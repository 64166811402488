import './navbar.css';
import './navbarMobile.css'
import {Logo, Portfolio} from '../../img';
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {checkScreenMode} from "../game/functions";
import useAnalyticsEventTracker from "../../helpers/UseAnalyticsEventTracker";

const Navbar = (props) => {
    let open = props.open;
    let setOpen = props.setOpen;
    let setHowToOpen = props.setHowToOpen;
    let setContactOpen = props.setContactOpen;
    let toggleTheme = props.theme;

    let navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('Navbar');

    useEffect(() => {
        if (open) {
            document.getElementById("sidenav").style.width = "235px";
        } else {
            document.getElementById("sidenav").style.width = "0px";
        }
    }, [open])

    return (
        <div id="sidenav" className="sidenav">
            <div id="sidenavLogoDiv">
                <img id="sidenavLogo" onClick={() => navigate("/")} src={Logo} />
                <a className="closebtn" onClick={() => {setOpen(!open)}}>&times;</a>
            </div>
            <a onClick={() => {setHowToOpen(true)}}>How To Play ❔</a>
            <a onClick={() => {
                gaEventTracker('support');
                navigate("/support");
                setOpen(false);
            }}>Support 💖</a>
            <a id="screenToggleBtn" onClick={() => {
                toggleTheme();
                checkScreenMode();
            }}>Dark Mode 🌙</a>
            <a onClick={() => {
                gaEventTracker('cookie-policy');
                navigate("/cookie-policy");
                setOpen(false);
            }}>Cookies 🍪</a>
            <a onClick={() => {
                gaEventTracker('contact');
                setContactOpen(true);
            }}>Contact 👋</a>

            <div className={'socialSidenav'}>
                <img onClick={() => window.open('https://ofox.co.uk/projects/albumdle/')} className={'socialSidenavIcon'} src={Portfolio} alt={"Link to Oliver Fox's portfolio website"} />
            </div>
        </div>
    )
}

export default Navbar;