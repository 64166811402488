import {Burger, LogoText, Share} from "../../img";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import './titleBar.css';
import './titleBarMobile.css';
import {styleShareModal} from "../game/functions";
import {fadeOutThenNavigate} from "../../helpers/pageTransitions";

const TitleBar = (props) => {
    let openNav = props.open;
    let setOpenNav = props.setOpen;
    let setResultsOpen = props.setResultsOpen;
    let theme = props.theme;
    let mode = props.mode;

    let navigate = useNavigate();
    let location = useLocation();

    let [pathname, setPathname] = useState(location.pathname);

    useEffect(() => {
        setPathname(location.pathname);
    }, [location])

    return (
        <div className={"title_container"}>
            <div className={`${theme}Invert`} id="title_div">
                <img alt="Side navigation bar" onClick={() => {
                    setOpenNav(!openNav);
                }} className="title_icons" id="burger_img" src={Burger}/>

                {(pathname !== "/") ?
                    <div id='title_img_div' className={"noSelect"} onClick={() => {
                        fadeOutThenNavigate(navigate, document.querySelector(".innerPageContainer")
                            .firstChild, "");
                    }}>
                        <object id="title_img" type="image/svg+xml" data={LogoText}></object>
                    </div>
                    :
                    <div></div>
                }

                {(pathname !== "/" && pathname !== "/cookie-policy") ?
                    <img alt="Share button" className="title_icons" id="results_img" src={Share}
                         onClick={() => {
                             styleShareModal(setResultsOpen, mode);
                         }}
                    />
                    :
                    <div style={{width: '20px'}}></div>
                }
            </div>
            <div className={`title_div_bg ${theme}`}></div>
        </div>

    )
}

export default TitleBar;