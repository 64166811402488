import {isMobile} from "../Game";
import {albumArt} from "./album-art-modified";

export async function getAlbumImg(album, artist) {
    let img = new Image();

    if (!isMobile) {
        await albumArt(artist, {album: album, size: 'large'}, (error, response) => {
            img.src = response;
            img.crossOrigin = "anonymous";
        })
    } else {
        await albumArt(artist, {album: album, size: 'medium'}, (error, response) => {
            img.src = response;
            img.crossOrigin = "anonymous";
        })
    }

    return img;
}