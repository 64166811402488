import './game.css';
import './gameMobile.css';

import {DownArrow, Loading} from "../../img";

import React, {useEffect, useRef, useState} from "react";

import {getAlbum, getAlbumList, getRandomAlbum, setRandomAlbum} from '../../firebase';

import $ from 'jquery';
import Modal from "../modal/Modal";
import Countdown from 'react-countdown';

import {
    autocomplete,
    checkResultTitleSize,
    checkScreenMode, createCookie, deleteCookie,
    createResultsShareText,
    getAlbumImg,
    getAverageRGB,
    getCookie,
    getMidnight,
    getRandomSeedNum,
    getTiles,
    invertColour,
    setResults,
    styleShareModal
} from "./functions";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga";
import useTimeout from "../../helpers/useTimeout";

const Game = (props) => {
    let mode = props.mode;
    let setMode = props.setMode;
    let theme = props.theme;
    let title = props.title;

    // Modals
    let setHowToOpen = props.setHowToOpen;
    let resultsOpen = props.resultsOpen;
    let setResultsOpen = props.setResultsOpen;
    let setCookieOpen = props.setCookieOpen;

    // Cookies
    let [midnight, setMidnight] = useState(getMidnight); // Used for cookie deletion
    let cookieText = props.setBannerText;

    // Album information
    let [album, setAlbum] = useState("");
    let [artist, setArtist] = useState("");
    let [arrayOfAlbums, setArrayOfAlbums] = useState([]); // Array of all possible albums from CSV
    let [albumData, setAlbumData] = useState([]); // Album information from Firebase

    // Player information
    let [playerResults, setPlayerResults] = useState([]);

    // Game variables
    let [randomSeedNum, setRandomSeedNum] = useState(getRandomSeedNum(mode)); // Random seed determined for tile placement
    let [imgLoaded, setImgLoaded] = useState(false);
    let [gameStarted, setGameStarted] = useState(false);
    let [tiles, setTiles] = useState([]);
    let [currentTile, setCurrentTile] = useState(null);
    let [correctTile, setCorrectTile] = useState(null);
    let [nums, setNums] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    let [incorrectNums, setIncorrectNums] = useState([]);
    let [skippedNums, setSkippedNums] = useState([]);
    let [gameWon, setGameWon] = useState(false);

    // Card variables
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    let innerWidth = windowSize.current[0];
    let [c, setC] = useState(null);
    const canvas = useRef(null);
    let [card, setCard] = useState(null);
    let [ctx, setCtx] = useState(null);
    let [img, setImg] = useState(new Image());
    let [w, setW] = useState(0);
    let [h, setH] = useState(0);
    let [imgW, setImgW] = useState(0);
    let [imgH, setImgH] = useState();
    let [tileDim, setTileDim] = useState(0); // Tile dimensions
    let [tileCountX, setTileCountX] = useState(0); // How many tiles can fit
    let [tileCountY, setTileCountY] = useState(0);
    let [imgData, setImgData] = useState(new Uint8ClampedArray([]));

    let navigate = useNavigate();

    // Functions to run once
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = title

        $(".gameDiv").fadeTo("fast", 1);

        if (albumData.length === 0) {
            if (mode === "") {
                setAlbumData(getAlbum());
            } else if (mode === "endless") {
                if ((isCompletedCookie() && getCookie("randomAlbum") !== "") || (getCookie("randomAlbum") !== "" && JSON.parse(getCookie("randomAlbum")).length !== 0)) {
                    let randomAlbum = JSON.parse(getCookie("randomAlbum"));
                    setAlbumData(randomAlbum);
                } else {
                    getNewRandomAlbum();
                }
            }
        }

        $('#albumInput').focus(function() {
            $('html, body').animate({
                scrollTop: $('#albumInput').offset().top
            }, 'fast');
        }).focusout(function() {
            $('html, body').animate({
                scrollTop: $('html').offset().top
            }, 'fast');
        });
    }, [])

    // Create the tiles and set the array
    useEffect(() => {
        if (tiles.length === 0 && imgData.length > 0 && imgW > 0 && tileDim > 0 && tileCountX > 0 && tileCountY > 0) {
            setTiles(getTiles(imgData, imgW, tileDim, tileCountX, tileCountY))
        }
    }, [imgData, imgW, tileCountX, tileCountY, tileDim])

    // When current tile is updated, draw the tiles
    useEffect(() => {
        let offset = 1.00;
        let heightOffset = 25;

        if (currentTile !== null) {
            ctx.putImageData(tiles[currentTile], tiles[currentTile].x * offset + (c.width/2 - img.width/2), tiles[currentTile].y * offset + heightOffset)
            const index = nums.indexOf(currentTile);
            if (index > -1) { // only splice array when item is found
                nums.splice(index, 1) // 2nd parameter means remove one item only
                // setNums(nums => nums.filter(num => num !== index));
            }
        }
    }, [currentTile]);

    // Check for game win
    useEffect(() => {
        if (gameWon) {
            setGameWon(false) // stop 're-revealing' animation from occurring
            reveal();
        }
    }, [gameWon]);

    // Set card height
    useEffect(() => {
        if (!isMobile) {
            $(".flip_card").height(700);
        } else {
            $(".flip_card").height(350);
        }
    }, [card]);

    // Setup canvas and context
    useEffect(() => {
        if (c != null && ctx == null) {
            setCtx(c.getContext("2d"))

            setW(innerWidth);
            setH(700);

            if (!isMobile) {
                c.width = innerWidth;
                c.height = 700;
            } else {
                c.width = innerWidth;
                c.height = 350;
            }
        }
    }, [c, ctx]);

    // Set album/artist info from Firebase data
    useEffect(() => {
        if (albumData.length > 0 && album === "" && artist === "") {
            setAlbum(albumData[0]);
            setArtist(albumData[1]);
        }
    }, [albumData]);

    // Wait for all album information and canvas properties before loading game
    useEffect(() => {
        if (album !== "" && artist !== "" && !img.src.includes("https") && !imgLoaded
            && canvas !== null && ctx !== null && c.width > 0) { // Got album from Firebase successfully
            async function albumImg() {
                setImgLoaded(true);
                const img = await getAlbumImg(album, artist)
                setImg(img);
                return img;
            }
            albumImg().then(r => {
                // wait for image data to load
                getMeta(r.src, (err, img) => {
                    startGame(img);
                });
            });
        } else {
            // Failed to get album
        }
    }, [album, artist, img, imgLoaded]);

    // Once list of albums in retrieved add to autocomplete field
    useEffect(() => {
        if (arrayOfAlbums.length > 0) {
            autocomplete(document.getElementById("albumInput"), arrayOfAlbums);
        }
    }, [arrayOfAlbums])
    
    useEffect(() => {
        setMode(mode);
        checkFirstPlay();
        checkScreenMode();

        if (arrayOfAlbums.length === 0) {
            getAlbumList(setArrayOfAlbums)
        }

        if (card == null) {
            setCard(document.querySelector(".flip_card"));
        }

        if (c == null) {
            setC(document.getElementById("canvas"));
        }

        if (mode === "endless" && (getCookie('cookies') === 'false' || getCookie('cookies') === "")) {
            cookieText("You must enable cookies to access Endless mode.")
            setCookieOpen(true);
        }

        // Once tiles have been created, start the game and remove loading
        if (tiles.length > 0 && !gameStarted) {
            setGameStarted(true);
            $("#loading_div").fadeTo("fast", 0, function () {
                document.getElementById("loading_div").remove();
            })

            if (isCompletedCookie()) {
                // add results to table
                if (getCookie(`${mode}results`) !== '') {
                    var json_str = getCookie(`${mode}results`);
                    var resultsArr = JSON.parse(json_str);
                    setPlayerResults(resultsArr);
                    for (var i = 0; i < resultsArr.length; i++) {
                        addToTable(resultsArr[i]);
                    }
                }

                setGameWon(true);
            } else {
                setResultsOpen(false);
                setRandomTile();
                // drawTiles();
            }
        }
    }, [gameStarted, tiles])

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            setMidnight(getMidnight());
        } else {
            return <div id={"countdown_text"} className={"text"}>Time until next <b>ALBUMDLE</b>:<br></br>{toTwoDigits(hours)}:{toTwoDigits(minutes)}:{toTwoDigits(seconds)}</div>;
        }
    };

    function toTwoDigits(number) {
        return ("0" + number).slice(-2)
    }

    function getNewRandomAlbum() {
        let valid = true;

        setRandomAlbum().then(() => {
            let randomAlbum = getRandomAlbum();
            if (getCookie("randomAlbumList") !== "") {
                let randAlbumList = JSON.parse(getCookie("randomAlbumList"));
                valid = !randAlbumList.includes(`${randomAlbum[0]}-${randomAlbum[1]}`);
            }

            if (valid) {
                createCookie("randomAlbum", JSON.stringify(randomAlbum), "forever");
                setAlbumData(randomAlbum);
            } else {
                getNewRandomAlbum();
            }
        })
    }

    function checkFirstPlay() {
        if (getCookie('firstPlay') === "") {
            setHowToOpen(true);
            document.cookie = "firstPlay=true; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
        }
    }

    function isCompletedCookie() {
        return getCookie(`${mode}completed`) === "true";
    }

    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = () => cb(null, img);
        img.onerror = (err) => nextAlbum();
        img.src = url;
        img.crossOrigin = "anonymous";
    };

    function startGame(img) {
        console.log("Game started")
        setBackCardProps(img); // Set colour of back of card based on average colour
        init(img);
    }

    function setBackCardProps(img) {
        let backCard = document.getElementById("flip_card_back");
        let colour = getAverageRGB(img).toString();
        backCard.style.backgroundColor = colour;
        backCard.style.color = invertColour(colour, true);
        document.getElementById("card_spine").style.backgroundColor = invertColour(colour, false);
        document.getElementById("album_result").innerHTML = album;
        document.getElementById("artist_result").innerHTML = artist;
        setResults(invertColour(colour, false), mode);

        if (isMobile) {
            checkResultTitleSize(); // check if title size is too large and needs reducing
        }
    }

    function init(img) {
        let size;
        if (!isMobile) {
            size = 640;
        } else {
            size = 300;
        }
        setImgW(size);
        setImgH(size);

        let tileDim = size / 4;
        setTileDim(tileDim);
        //check how many full tiles we can fit
        //right and bottom sides of the image will get cropped
        setTileCountX(~~(size / tileDim))
        setTileCountY(~~(size / tileDim))

        ctx.drawImage(img, 0, 0);
        setImgData(ctx.getImageData(0, 0, size, size).data);
        ctx.clearRect(0, 0, w, h);
    }

    //and draw with offset
    var offset = 1.00;
    var heightOffset = 25;
    function drawTiles() {
        var randomNum = Math.floor(randomSeedNum * nums.length);
        setCurrentTile(nums[randomNum]);
        ctx.putImageData(tiles[nums[randomNum]], tiles[nums[randomNum]].x * offset + (c.width/2 - img.width/2), tiles[nums[randomNum]].y * offset + heightOffset)
        const index = nums.indexOf(nums[randomNum]);
        if (index > -1) { // only splice array when item is found
            nums.splice(index, 1) // 2nd parameter means remove one item only
        }
    }

    function nextTile(skipped) {
        if (skipped) {
            setSkippedNums([...skippedNums, currentTile])
        }

        // If user has finished and the share text isn't created
        if (nums.length === 0 && getCookie(`${mode}completed`) !== 'true') {
            createCookie(`${mode}tiles`, JSON.stringify(nums), midnight);
            createCookie(`${mode}incorrecttiles`, JSON.stringify(incorrectNums), midnight);
            createCookie(`${mode}skippedtiles`, JSON.stringify(skippedNums), midnight);
            reveal();
        // If player has completed then "next tile" is "turn over" and therefore flip card
        } else if (nums.length === 0) {
            if (document.querySelector(".flip_card_inner").style.transform === "rotateY(180deg)") {
                document.querySelector(".flip_card_inner").style.transform = "rotateY(0deg)"
            } else {
                document.querySelector(".flip_card_inner").style.transform = "rotateY(180deg)"
            }
        }

        if (nums.length !== 0) {
            setRandomTile();
        }
    }

    function setRandomTile() {
        let randomTile = Math.floor(randomSeedNum * nums.length)
        if (nums.length === 16 && mode === "endless") {
            if (getCookie("endlessRandTile") === "") {
                createCookie("endlessRandTile", randomTile, "forever");
            } else {
                randomTile = getCookie("endlessRandTile");
            }
        }
        setCurrentTile(nums[randomTile]);
    }

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    async function reveal() {
        if (nums.length === 0) {
            printAnswer();
        } else {
            for (var i = 0; i < tiles.length; i++) {
                if (nums.length > 0) {
                    await sleep(45);
                    drawTiles();
                }

                // setRandomTile();
                // setTimeout(() => drawTiles(), 150);


            }

            if (nums.length <= 1) {
                await sleep(400)
                printAnswer();
                // setTimeout(() => printAnswer(), 500);
            }
        }

        setTimeout(() => styleShareModal(setResultsOpen, mode), 1250);
    }

    function printAnswer() {
        if (!isCompletedCookie()) {
            var amountOfTiles = 0;
            if (getCookie(`${mode}tiles`) !== "") {
                amountOfTiles = (16 - parseInt(JSON.parse(getCookie(`${mode}tiles`)).length));
            }

            var count = 1;

            if (getCookie(`${mode}pastresults${amountOfTiles}`) !== "") {
                count += parseInt(getCookie(`${mode}pastresults${amountOfTiles}`))
            }
            createCookie(`${mode}pastresults${amountOfTiles}`, `${count}`, "forever");
        }

        if (getCookie(`${mode}correctTile`) !== "") {
            setCorrectTile(parseInt(getCookie(`${mode}correctTile`)));
        } else {
            createCookie(`${mode}correctTile`, `${correctTile}`, midnight);
        }

        setResults(invertColour(getAverageRGB(img), false), mode);

        if (document.querySelector(".flip_card_inner").style.transform === "rotateY(180deg)") {
            document.querySelector(".flip_card_inner").style.transform = "rotateY(0deg)"
        } else {
            document.querySelector(".flip_card_inner").style.transform = "rotateY(180deg)"
        }

        document.getElementById("share_text").innerHTML = createResultsShareText(false, mode);

        if (!isCompletedCookie()) {
            createCookie(`${mode}completed`, "true", (mode === "") ? midnight : "forever");
            createCookie(`${mode}results`, JSON.stringify(playerResults), (mode === "") ? midnight : "forever")
        }

        document.getElementById('nextTileBtn').textContent = 'TURN OVER'

        if (mode === "") {
            document.getElementById('submitButton').textContent = 'RESULTS'
        } else if (mode === "endless") {
            document.getElementById('submitButton').textContent = 'NEXT ALBUM'
        }

        if (mode === "endless") {
            if (getCookie("randomAlbumList") === "") {
                let randAlbumList = [`${album}-${artist}`];
                createCookie("randomAlbumList", JSON.stringify(randAlbumList), "forever");
                createCookie("randomAlbumListNo", 1, "forever");
            } else {
                let randAlbumList = JSON.parse(getCookie("randomAlbumList"));
                let value = `${album}-${artist}`;
                if (!randAlbumList.includes(value)) {
                    randAlbumList.push(value);
                    createCookie("randomAlbumList", JSON.stringify(randAlbumList), "forever");
                    createCookie("randomAlbumListNo", randAlbumList.length, "forever");
                }
            }
        }
    }

    function submitAnswer() {
        if (nums.length > 0) {
            // const userAnswer = document.getElementById('albumInput').value.toLowerCase().split(',')[0].trimStart().replaceAll(" ", "");
            const userAnswerArr = document.getElementById('albumInput').value.toLowerCase().split(',');
            userAnswerArr.pop();

            let userAnswer = userAnswerArr.join("").replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '').replaceAll('_', '');
            const answer = (album).toLowerCase().trimStart().replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '').replaceAll('_', '');

            addToTable(document.getElementById('albumInput').value)
            document.getElementById('albumInput').value = "";

            if (userAnswer.normalize() === answer.normalize()) {
                createCookie(`${mode}tiles`, JSON.stringify(nums), midnight);
                createCookie(`${mode}incorrecttiles`, JSON.stringify(incorrectNums), midnight);
                createCookie(`${mode}skippedtiles`, JSON.stringify(skippedNums), midnight);
                setCorrectTile(currentTile);
                setGameWon(true);
            } else {
                // incorrect answer
                setIncorrectNums([...incorrectNums, currentTile]);
                changeSVGColor((theme === "light") ? "red" : "#00ffff")
                window.setTimeout(function () {
                    changeSVGColor('black')
                }.bind(this), 750);
                nextTile(false);
            }
        } else {
            if (!isCompletedCookie()) {
                setGameWon(true);
            }
        }
    }

    function changeSVGColor(color) {
        var svg = document.getElementById("title_img").contentDocument;
        var elements = svg.getElementsByClassName("primaryColor");
        for (var i = 0; i < elements.length; i++) elements[i].style.fill = color;
    }

    function addToTable(answer) {
        var table = document.getElementById("answersTable");
        var row = table.insertRow(0);
        var cell1 = row.insertCell(0);


        if (answer === "") {
            cell1.innerHTML = `<i>Skipped</i>`;
        } else {
            let answerArr = answer.split(',');
            let artist = answerArr.pop();
            cell1.innerHTML = `${answerArr.toString()}, <i>${artist}</i>`;
        }
        setPlayerResults([...playerResults, answer]);
    }

    function nextAlbum() {
        deleteCookie(`randomAlbum`);
        deleteCookie(`endlessRandTile`);
        deleteCookie(`${mode}results`);
        deleteCookie(`${mode}tiles`);
        deleteCookie(`${mode}incorrecttiles`);
        deleteCookie(`${mode}skippedtiles`);
        getNewRandomAlbum();
        deleteCookie(`${mode}completed`);
        navigate(0)
        // setRandomTile();
        // setCtx(null)
        // setC(null)
        // setTiles([]);
        // img.src = "";
        // setImgLoaded(false);
        // setResultsOpen(false);
    }

    return (
        <div className="gameDiv">
            {/* Results Modal */}
            <Modal name="results" open={resultsOpen} setOpen={setResultsOpen} content={
                <div style={{textAlign: 'center'}} className={`${theme}Modal ${theme}Text modal_content`} id="modalContent">
                    <span onClick={() => {setResultsOpen(false)}} className="close">&times;</span>
                    <h2 id="todays_result">{mode === "" ? "TODAY'S RESULT" : "ENDLESS RESULT"}</h2>
                    <p id="share_text"></p>
                    <button className={`${theme}Button shareModalButton noSelect`} id="share_button">SHARE RESULTS</button>
                    {(mode === "endless" && getCookie(`${mode}completed`) === "true") ?
                        <button className={`${theme}Button shareModalButton noSelect`} id="next_button"
                                onClick={() => {nextAlbum()}}>
                            NEXT ALBUM
                        </button>
                        :
                        <div></div>
                    }
                    <p id="error_msg"></p>
                    <br></br>
                    {(mode === "") ?
                        <Countdown
                            date={midnight}
                            renderer={renderer}
                        />
                    :
                        <div></div>
                    }
                </div>
            }>
            </Modal>

            <div id="flip_card" className="flip_card">
                <div id="loading_div">
                    <img id="loading_anim" alt="Loading animation for album cover" src={Loading}/>
                </div>
                <div className="flip_card_inner">
                    <div className="flip_card_front">
                        <canvas id='canvas' ref={canvas} style={{left: 0, right: 0, top: 0, bottom: 0}}></canvas>
                    </div>
                    <div id="flip_card_back" className="flip_card_back">
                        <div id="card_spine" style={{float: 'left', width: '5%', height: '100%'}}></div>
                        <div>
                            <p id="album_result">Album</p>
                            <p id="artist_result">Artist</p>
                            <div id="track_list_result_container">
                                <div style={{float: 'left', width: '45%'}}>
                                    <ol>
                                        <li className="li_result" id="li_one"></li>
                                        <li className="li_result" id="li_two"></li>
                                        <li className="li_result" id="li_three"></li>
                                        <li className="li_result" id="li_four"></li>
                                        <li className="li_result" id="li_five"></li>
                                        <li className="li_result" id="li_six"></li>
                                        <li className="li_result" id="li_seven"></li>
                                        <li className="li_result" id="li_eight"></li>
                                    </ol>
                                </div>
                                <div style={{float: 'left', width: '45%'}}>
                                    <ol start="9">
                                        <li className="li_result" id="li_nine"></li>
                                        <li className="li_result" id="li_ten"></li>
                                        <li className="li_result" id="li_eleven"></li>
                                        <li className="li_result" id="li_twelve"></li>
                                        <li className="li_result" id="li_thirteen"></li>
                                        <li className="li_result" id="li_fourteen"></li>
                                        <li className="li_result" id="li_fifteen"></li>
                                        <li className="li_result" id="li_sixteen"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Buttons and results container*/}
            <div id="completionDiv" style={{textAlign: 'center'}}>
                <div className={`${theme}Invert autocomplete`}>
                    <input autoComplete={'off'} id="albumInput" type="text" name="albumGuess" placeholder='Enter album here ...'/>
                </div>
                <div id="button_div">
                    <button onClick={() => {
                        nextTile(true);
                    }} className={`${theme}Button nextTileButton noSelect`} id={"nextTileBtn"}>
                        NEXT TILE
                    </button>
                    <button onClick={() => {
                        if (mode === "") {
                            (getCookie(`${mode}completed`) === "true" ? setResultsOpen(true) : submitAnswer());
                        } else if (mode === "endless") {
                            (getCookie(`${mode}completed`) === "true" ? nextAlbum() : submitAnswer());
                        }
                    }} className={`${theme}SubmitButton submitButton noSelect`} id={"submitButton"}>
                        SUBMIT
                    </button>
                </div>
                <br/>
                <p className={`${theme}Text`} id="results_text">RESULTS</p>
                <img className={`${theme}Invert`} alt="Down arrow below results" src={DownArrow} width="16px"/>
                <div className={`${theme}Text`} style={{display: 'flex', justifyContent: 'center'}}>
                    <table id="answersTable">
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Game;
export const isMobile = window.matchMedia("only screen and (max-width: 480px)").matches;