import {useEffect} from "react";
import $ from "jquery";
import ReactGA from "react-ga";

const CookiePolicy = (props) => {
    let theme = props.theme;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        $(".cookiePage").fadeTo("fast", 1);
    }, [])

    return (
        <div className={`${theme}Text cookiePage`} style={{opacity: '0', margin: '10px'}}>
            <div style={{marginBottom: '1rem'}}>Effective as of: 2022-07-31 16:01:20</div>

            <h3 style={{textAlign: 'center'}}>COOKIE POLICY</h3>

            <div style={{marginBottom: '1rem'}}>In this Cookie Policy we will provide you with detailed information on how Albumdle
                (hereinafter - the &quot;we&quot; or &quot;our&quot;), shall undertake to ensure the security of personal
                information and the protection of rights of the visitors and users of the websites
                (hereinafter - the &quot;Visitors&quot;, &quot;You&quot;) while you use our websites including but not limited
                to <a className={`${theme}Link`} href="https://albumdle.com/">https://albumdle.com/</a> (hereinafter - the &quot;Site&quot;) and the content on it.</div>

            <div style={{marginBottom: '1rem'}}>
                <h3 style={{fontWeight: 700, margin: 0, textTransform: 'uppercase'}}>1. What is a cookie?</h3>
                <div style={{margin: '.5rem 0'}}>A cookie is a small file placed onto your device that enables our Site features and
                    functionality. For example, cookies can enable us to identify your device and secure your access to the
                    Site. Cookies also allow the Site to remember information about your browsing on the Site for a while and to
                    recognize you the next time you visit the Site. All this allows us to give you the opportunity to use the
                    Site comfortably and to make the Site even more user-friendly.
                </div>
            </div>

            <div style={{marginBottom: '1rem'}}>
                <h3 style={{fontWeight: 700, margin: 0, textTransform: 'uppercase'}}>2. Why do we use cookies?</h3>
                <div style={{margin: '.5rem 0'}}>We use cookies for the following main purposes:</div>
                <ul>
                    <li>To ensure efficient and safe functioning of the Site. We use cookies to enable and support our security
                        features, and to help us detect malicious activity on our Site.</li>
                    <li>To understand, improve, and research products, features, and services, including when you access our Site from other websites or devices
                        such as your computer or your mobile device.</li>
                    <li>To recognize the returning visitors of the Site. Cookies help us show you the right information and
                        personalize your experience. Cookies also help avoiding re-registration or re-filling of the information
                        by you each time you visit the Site.</li>
                </ul>
            </div>

            <div style={{marginBottom: '1rem'}}>
                <h3 style={{fontWeight: 700, margin: 0, textTransform: 'uppercase'}}>3. What cookies do we use?</h3>
                <div style={{margin: '.5rem 0'}}>Each time you visit our Site, the long-term (persistent) cookies may be created,
                    which stay in your browser after you sign-up and will be read by us when you return to our Site and not
                    deleted after you finish browsing our Site, and the short-term (session) cookies, which expire or are
                    deleted after you finish browsing our Site (i.e. they usually last during the current visit to our Site or
                    browsing session).
                </div>

                <div style={{margin: '.5rem 0'}}>Cookies used by the Company:</div>
                <ul>
                    <li>Strictly required or necessary cookies. These cookies are required for the operation of our Site. They
                        include, for example, cookies that enable storage of information filled by you during the browsing
                        session, enable you to log into secure areas of our Site. Without these cookies operation of the Site
                        would be impossible or its functioning may be severely affected.</li>
                    <li>Preferences cookies. These improve the functional performance of our Site and make it easier for you to
                        use. These cookies remember the settings selected by the Visitors (for example, the settings of language
                        or currency). With the use of these cookies, the Visitors may avoid the changes of settings during each
                        visit of the Site. These cookies also remember changes made by you in the Site (for example, in case
                        you leave comment on the Site). These cookies do not track your behavior in other websites.</li>
                </ul>

                <div style={{margin: '.5rem 0'}}>Third-party cookies:</div>
                <ul>
                    <li>We use Google Analytics, a web analysis service provided by Google, Inc (hereinafter referred to as
                        &quot;Google&quot;). The information collected by Google Analytics is transmitted to and stored with
                        Google. Google may transmit the information collected by Google Analytics to the third parties as
                        required by the law or when those third parties process the information in the name of Google. We
                        recommend consulting the Google Privacy and Cookies Policy on a separate and regular basis.</li>
                </ul>
            </div>

            <div style={{marginBottom: '1rem'}}>
                <h3 style={{fontWeight: 700, margin: 0, textTransform: 'uppercase'}}>4. How to refuse or block cookies?</h3>
                <div style={{margin: '.5rem 0'}}>Many web browsers are set so that they would automatically accept all cookies.</div>
                You may refuse and make a preference which cookies you allow by choosing it in our Cookie Consent Banner.
                Also all the cookies will be set if you accept all by clicking &quot;Accept All&quot; on our Cookie Consent
                Banner.
            </div>

            <div style={{margin: '.5rem 0'}}>The Visitors may, at their discretion, manage, block or delete cookies, if the settings
                of their browser or device enable them to do so. Nevertheless, if you refuse or block the cookies or other similar
                technologies, some functions of the Site may be inaccessible to you or they may operate not properly.</div>
            <div style={{margin: '.5rem 0'}}>We draw your attention that necessary cookies are critical for functioning of our Site,
                and in case of your objections, some features of the Site may not work or may not work properly.</div>
            <div style={{margin: '.5rem 0'}}>You may require that we delete all the data about you, as collected and processed with
                the help of the cookies, by contacting to the email address <a className={`${theme}Link`} href="mailto:contact@albumdle.com">contact@albumdle.com</a>.</div>
            <div style={{margin: '.5rem 0'}}>You can opt out of Google Analytics without affecting how you visit our Site. For more
                information on opting out of being tracked by Google Analytics across all websites you use, visit this Google
                page: <a className={`${theme}Link`} href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</div>
            <div style={{margin: '.5rem 0'}}>You may find more information about how to delete cookies, as well as the other useful
                information related to the use of the cookies, on the website <a className={`${theme}Link`} href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.
            </div>

            <div style={{marginBottom: '1rem'}}>
                <h3 style={{fontWeight: 700, margin: 0, textTransform: 'uppercase'}}>5. Do we update Cookie Policy?</h3>
                <div style={{margin: '.5rem 0'}}>This Cookie Policy may be updated by us from time to time. We will inform you about
                    the updates, by providing the new version of the Cookie Policy. For this reason, we recommend you
                    periodically visit our Site, where you will always find the latest version of this Cookie Policy. </div>
            </div>
        </div>
    )
}

export default CookiePolicy;