import './stylesheets';

import $ from 'jquery';
import {Logo} from "../../img";
import {useNavigate} from "react-router-dom";
import {fadeOutThenNavigate} from "../../helpers/pageTransitions";
import {useEffect} from "react";
import useAnalyticsEventTracker from "../../helpers/UseAnalyticsEventTracker";
import ReactGA from "react-ga";

const Home = (props) => {
    let theme = props.theme;
    let title = props.title;

    let navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('Home');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = title;
        $(".homeDiv").fadeTo("fast", 1);
    }, [])

    return (
        <div className={"homeDiv"}>
            <img className={"homeLogo"} src={Logo}  alt={"Albumdle logo"}/>
            {/*<div className={`${theme}Text homeAuthor`}>*/}
            {/*    <i>Developed by Oliver Fox</i>*/}
            {/*</div>*/}
            <button className={`homeButton ${theme}HomeButton noSelect`} onClick={() => {
                gaEventTracker('daily');
                fadeOutThenNavigate(navigate, ".homeDiv", "daily");
            }}>DAILY</button>
            <button className={`homeButton ${theme}HomeButton noSelect`} onClick={() => {
                gaEventTracker('endless');
                fadeOutThenNavigate(navigate, ".homeDiv", "endless");
            }}>ENDLESS</button>
        </div>
    )
}

export default Home;