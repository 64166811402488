export function checkResultTitleSize() {
    let albumResult = document.getElementById("album_result");
    let artistResult = document.getElementById("artist_result");
    let titleHeight = albumResult.clientHeight;

    if (titleHeight > 100) {
        albumResult.style.fontSize = "15px"
        artistResult.style.fontSize = "15px"
    } else if (titleHeight > 70 && titleHeight <= 100) {
        albumResult.style.fontSize = "17px"
        artistResult.style.fontSize = "17px"
    } else {
        albumResult.style.fontSize = "25px"
    }
}