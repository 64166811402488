import {useEffect} from "react";
import $ from "jquery";
import ReactGA from "react-ga";
import './support.css';
import './supportMobile.css';
import {ThreeDLogo} from "../../img";

const Support = (props) => {
    let theme = props.theme;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        $(".support").fadeTo("fast", 1);
    }, [])

    return (
        <div className={`${theme}Text support`}>
            <img className={'TDLogo'} src={ThreeDLogo} alt={'3D version of Albumdle logo'}/>
            <div>
                Thank you for playing ALBUMDLE, this was a small side project I setup in my spare time and have put it up
                for free for you to enjoy. You can check out more of my projects and about me <a className={`${theme}Link`} target="_blank" rel="noopener noreferrer" href={"https://ofox.co.uk"}>here</a>.
            </div>
            <br/>
            <div>
                If you're <i>really</i> enjoying ALBUMDLE you can <a className={`${theme}Link`} target="_blank" rel="noopener noreferrer" href={"https://ko-fi.com/albumdle"}>buy me a coffee on Ko-fi</a>,
                it'll help me through all my work I should really be doing :)
            </div>
        </div>
    )
}

export default Support;