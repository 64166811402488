// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {collection, getDocs, getFirestore, query, where, limit, getCountFromServer} from "firebase/firestore";
import {getDownloadURL, getStorage, ref} from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAsnVRZZFG3NAwan_TVJBvbEJWk5O0_I2E",
    authDomain: "albumdle-fox.firebaseapp.com",
    projectId: "albumdle-fox",
    storageBucket: "albumdle-fox.appspot.com",
    messagingSenderId: "101519543882",
    appId: "1:101519543882:web:ed1d623887986eef48e0b3",
    measurementId: "G-ZBHCEPNSDE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

var album = [];

const q = query(collection(db, "albums"), where("today", "==", true));

const querySnapshot = await getDocs(q);
querySnapshot.forEach((doc) => {
    let albumData = doc.data();

    album.push(albumData.album);
    album.push(albumData.artist);
});



export function getAlbum() {
    return album;
}

export function getAlbumList(setArrayOfAlbums) {
    let albumList = [];
    const pathReference  = ref(storage, 'albums/albums.csv');
    getDownloadURL(pathReference).then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'text';
        xhr.onload = (event) => {
            albumList = xhr.response.split(/\r?\n/);
            setArrayOfAlbums(albumList);
        };
        xhr.open('GET', url);
        xhr.send();
    });
}

let randomAlbum = []
export async function setRandomAlbum() {
    randomAlbum = [];
    const pastAlbums = query(collection(db, "pastAlbums"));
    const snapshot = await getCountFromServer(pastAlbums);
    let randomNum = Math.floor(Math.random() * snapshot.data().count);

    const randQ = query(collection(db, "pastAlbums"), where("random", ">=", randomNum), limit(1));
    const querySnapshot = await getDocs(randQ);

    querySnapshot.forEach((doc) => {
        let albumData = doc.data();

        randomAlbum.push(albumData.album);
        randomAlbum.push(albumData.artist);
    });
}

export function getRandomAlbum() {
    return randomAlbum;
}