import {getCookie} from "./cookies";

export function createResultsShareText(share, mode) {
    var resultsArr = [];
    if (getCookie(`${mode}tiles`) !== "") {
        resultsArr = JSON.parse(getCookie(`${mode}tiles`));
    }
    var incorrectArr = [];
    if (getCookie(`${mode}incorrecttiles`) !== "") {
        incorrectArr = JSON.parse(getCookie(`${mode}incorrecttiles`));
    }
    var skippedArr = [];
    if (getCookie(`${mode}skippedtiles`) !== "") {
        skippedArr = JSON.parse(getCookie(`${mode}skippedtiles`));
    }

    var text = "";
    for (var j = 0; j < 16; j++) {
        if (j % 4 === 0 && j !== 0) {
            if (share) {
                text += "\n";
            } else {
                text += "<br>";
            }
        }
        if (resultsArr.includes(j) || resultsArr.length === 0) {
            text += "⬛️";
        } else if (skippedArr.includes(j)) {
            text += "🟨";
        } else if (incorrectArr.includes(j)) {
            text += "🟥";
        } else {
            text += "🟩️";
        }
    }

    return text;
}