function rand_from_seed(x, iterations){
    iterations = iterations || 1000;
    for (var i = 0; i < iterations; i++)
        x = (x ^ (x << 1) ^ (x >> 1)) % 1000;
    return x;
}

export function getRandomSeedNum(mode) {
    if (mode === "") {
        var dateVal = new Date();
        dateVal = dateVal.getTime() - dateVal.getTimezoneOffset()*60000;
        var random = rand_from_seed(~~(dateVal.valueOf()/86400000));

        return random / Math.pow(10, random.toString().length)
    } else {
        return Math.random();
    }
}