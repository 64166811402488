import './cookieBanner.css'
import {useEffect} from "react";
import {createCookie} from "../game/functions";

const CookieBanner = (props) => {
    let open = props.open;
    let setOpen = props.setOpen;

    let bannerText = props.bannerText;
    let setBannerText = props.setBannerText;

    useEffect(() => {
        if (open) {
            openCookieBanner();
        } else {
            closeCookieBanner();
        }
    }, [open]);

    let banner = document.getElementById('cookie-content-banner');
    let bg = document.getElementById('cookie-bg');

    function acceptCookies() {
        window['ga-disable-G-ZBHCEPNSDE'] = false;
        createCookie('cookies', 'true', 'forever');
        createCookie('firstVisit', 'false', 'forever');

        setOpen(false);
    }

    function declineCookies() {
        window['ga-disable-G-ZBHCEPNSDE'] = true;
        createCookie('cookies', 'false', 'forever');
        createCookie('firstVisit', 'false', 'forever');

        setOpen(false);
    }

    function closeCookieBanner() {
        if (banner != null && bg != null) {
            banner.style.display = 'none';
            bg.style.display = 'none';
        }

        if (bannerText.includes("Endless")) {
            setBannerText("We use cookies to personalise content, and to analyse our traffic. We also share information about your use of our site with our analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.");
            window.location.reload();
        }
    }

    function openCookieBanner() {
        if (banner != null && bg != null) {
            banner.style.display = 'block';
            banner.style.visibility = 'visible'
            bg.style.display = 'block';
            bg.style.visibility = 'visible'
        }
    }

    return (
        <div>
            <div id="cookie-content-banner" className="cookie-consent-banner">
                <div className="cookie-consent-banner__inner">
                    <div className="cookie-consent-banner__copy">
                        <div className="cookie-consent-banner__header">THIS WEBSITE USES COOKIES</div>
                        <div className="cookie-consent-banner__description">
                            {bannerText}
                        </div>
                    </div>

                    <div className="cookie-consent-banner__actions">
                        <a onClick={() => {acceptCookies()}} className="cookie-consent-banner__cta" id="cookie_accept_btn">
                            OK
                        </a>

                        <a onClick={() => {declineCookies()}}
                           className="cookie-consent-banner__cta cookie-consent-banner__cta--secondary">
                            Decline
                        </a>
                    </div>
                </div>
            </div>
            <div id="cookie-bg"></div>
        </div>
    )
}

export default CookieBanner;